import { PanelPlugin } from '@grafana/data';
import { MachineDetailOptions } from './types';
import { MachineDetailPanel } from './MachineDetailPanel';

export const plugin = new PanelPlugin<MachineDetailOptions>(MachineDetailPanel).setPanelOptions(builder => {
  return builder
  .addBooleanSwitch({
    path: 'isEnglish',
    name: 'is English',
    description: 'Select the mode of either English or Japanese.',
    defaultValue: false,
  });
  // .addTextInput({
    //   path: 'text',
    //   name: 'Simple text option',
    //   description: 'Description of panel option',
    //   defaultValue: 'Default value of text input option',
    // })
    // .addBooleanSwitch({
    //   path: 'showSeriesCount',
    //   name: 'Show series counter',
    //   defaultValue: false,
    // })
    // .addRadio({
    //   path: 'seriesCountSize',
    //   defaultValue: 'sm',
    //   name: 'Series counter size',
    //   settings: {
    //     options: [
    //       {
    //         value: 'sm',
    //         label: 'Small',
    //       },
    //       {
    //         value: 'md',
    //         label: 'Medium',
    //       },
    //       {
    //         value: 'lg',
    //         label: 'Large',
    //       },
    //     ],
    //   },
    //   showIf: config => config.showSeriesCount,
    // });
});
