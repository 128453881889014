import React from 'react';
import { PanelProps, GrafanaTheme2, FieldConfig, DisplayValue, ThresholdsConfig, ThresholdsMode, FieldColorModeId, VizOrientation } from '@grafana/data';
import { MachineDetailOptions, PowderInfo, PowderIconInfo } from 'types';
import { css, cx } from 'emotion';
import { useStyles2, useTheme2, Gauge, BarGauge, BarGaugeDisplayMode } from '@grafana/ui';
// import { VizTextDisplayOptions } from '@grafana/schema';
// import mystyles from './styles/style.module.css'
import CriticalIcon from './img/icon_error_critical_36x36.png';
import WarnIcon from './img/icon_error_warn_36x36.png';
import { ReactFitty } from "react-fitty";

const designColors = {
  "Success": "#0E8757",
  "Error": "#D93A3F",
  "Warn": "#F9F91C",
  "Gray800": "#BFC3C7",
  "Gray700": "#95999E",
  "Gray400": "#3F444B",
  "Gray100": "#21262D",
  "White": "white",
  "Black": "black",
};

const ItemTitlesJa = {
  "ProcessJobName": "加工ジョブ名",
  "ProcessRemainTime": "加工残り時間",
  "Oximeter": "酸素濃度",
  "PowderKind": "搭載粉体",
  "PowderRemain": "粉体残量",
  "Model": "機種",
  "SoftwareVersion": "ソフトウェアVer.",
  "SerialNumber": "装置S/N",
  "MaintainanceLimit": "定期メンテナンス期限",
  "Machine": "装置",
  "ApfPowders": "APF搭載粉体",
  "InUse": "使用中",
}

const ItemTitlesEn = {
  "ProcessJobName": "Job name",
  "ProcessRemainTime": "Remaining processing time",
  "Oximeter": "Oxygen concentration",
  "PowderKind": "Loaded powder",
  "PowderRemain": "Remaining powder",
  "Model": "Model",
  "SoftwareVersion": "Software version",
  "SerialNumber": "Machine serial number",
  "MaintainanceLimit": "Maintenance due", // Periodic maintenance deadline
  "Machine": "Machine",
  "ApfPowders": "Powder tanks in APF",
  "InUse": "In use",
}

interface Props extends PanelProps<MachineDetailOptions> {}

export const MachineDetailPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);

  const serialNumber = data.series
  .map(series => series.fields.find(field => field.name === 'fwSerial'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const machineName = data.series
  .map(series => series.fields.find(field => field.name === 'machineName'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const modelName = data.series
  .map(series => series.fields.find(field => field.name === 'modelName'))
  .map(field => field?.values.get(field.values.length - 1));

  const oxygenConcentration = data.series
  .map(series => series.fields.find(field => field.name === 'oxygenConcentration'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const powderInstalled = data.series
  .map(series => series.fields.find(field => field.name === 'powderInstalled'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const powderRemain = data.series
  .map(series => series.fields.find(field => field.name === 'powderRemain'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const processJobName = data.series
  .map(series => series.fields.find(field => field.name === 'processJobName'))
  .map(field => field?.values.get(field.values.length - 1));

  const processProgressRate = data.series
  .map(series => series.fields.find(field => field.name === 'processProgressRate'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const processRemainTime = data.series
  .map(series => series.fields.find(field => field.name === 'processRemainTime'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const processEndTime = data.series
  .map(series => series.fields.find(field => field.name === 'processEndTime'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const statusValue = data.series
  .map(series => series.fields.find(field => field.name === 'status'))
  .map(field => field?.values.get(field.values.length - 1));

  const statusStr = data.series
  .map(series => series.fields.find(field => field.name === 'statusStr'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const softwareVersion = data.series
  .map(series => series.fields.find(field => field.name === 'softwareVersion'))
  .map(field => field?.values.get(field.values.length - 1));
 
  const maintenanceSchedule = data.series
  .map(series => series.fields.find(field => field.name === 'maintenanceSchedule'))
  .map(field => field?.values.get(field.values.length - 1));

  //以下、APF用
  const apfSerialNumber = data.series
  .map(series => series.fields.find(field => field.name === 'option_device1'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfSoftwareVersion = data.series
  .map(series => series.fields.find(field => field.name === 'option_software_version1'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderInstalled1 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_installed1'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderRemain1 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_remain1'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfIsPowderUsed1 = data.series
  .map(series => series.fields.find(field => field.name === 'is_powder_usage_status1'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderInstalled2 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_installed2'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderRemain2 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_remain2'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfIsPowderUsed2 = data.series
  .map(series => series.fields.find(field => field.name === 'is_powder_usage_status2'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderInstalled3 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_installed3'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderRemain3 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_remain3'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfIsPowderUsed3 = data.series
  .map(series => series.fields.find(field => field.name === 'is_powder_usage_status3'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderInstalled4 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_installed4'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfPowderRemain4 = data.series
  .map(series => series.fields.find(field => field.name === 'powder_remain4'))
  .map(field => field?.values.get(field.values.length - 1));

  const apfIsPowderUsed4 = data.series
  .map(series => series.fields.find(field => field.name === 'is_powder_usage_status4'))
  .map(field => field?.values.get(field.values.length - 1));

  //扱いやすいように配列にまとめる
  const apfPowderInfoList: PowderInfo[] = [
    {powderInstalled: apfPowderInstalled1[0], powderRemain: apfPowderRemain1[0], isPowderUsed: stringToBoolean(apfIsPowderUsed1[0]), iconInfo: createPowderIconInfo(apfPowderRemain1[0])},
    {powderInstalled: apfPowderInstalled2[0], powderRemain: apfPowderRemain2[0], isPowderUsed: stringToBoolean(apfIsPowderUsed2[0]), iconInfo: createPowderIconInfo(apfPowderRemain2[0])},
    {powderInstalled: apfPowderInstalled3[0], powderRemain: apfPowderRemain3[0], isPowderUsed: stringToBoolean(apfIsPowderUsed3[0]), iconInfo: createPowderIconInfo(apfPowderRemain3[0])},
    {powderInstalled: apfPowderInstalled4[0], powderRemain: apfPowderRemain4[0], isPowderUsed: stringToBoolean(apfIsPowderUsed4[0]), iconInfo: createPowderIconInfo(apfPowderRemain4[0])},
  ]

  //APF接続の有無を判定する関数。APFのS/Nが空, null, undefinedならば接続なしと判断。判断基準をmui5の一覧画面と統一
  const noApf = () => {
    return     apfSerialNumber[0] === ""
            || apfSerialNumber[0] == null 
            || apfSerialNumber[0] === undefined 
  }

  let statusColor = designColors.White;
  let statusBgColor = designColors.Success;
  let statusOpacity = 1.0;
  let oxygenConcentrationFlag = true
  // システムエラー or 酸素濃度センサーエラー
  if (statusValue[0] === 18 || statusValue[0] === 21) {
    statusColor = designColors.White;
    statusBgColor = designColors.Error;
  }
  // シャットダウン、待機中
  else if (statusValue[0] === -2 ||
    statusValue[0] === 3 || statusValue[0] === 4 || statusValue[0] === 5) {
    statusColor = designColors.Gray800;
    statusBgColor = designColors.Gray400;
    oxygenConcentrationFlag = false
  }
  else if (statusValue[0] === 22 || statusValue[0] === 23) {
    statusColor = designColors.Black;
    statusBgColor = designColors.Warn;
  }
  else if (statusValue[0] > 0) {
    statusColor = designColors.White;
    statusBgColor = designColors.Success;
  }
  // 通信不能、状態不明
  else
  {
    statusColor = designColors.Gray700;
    statusBgColor = designColors.Gray400;
    statusOpacity = 0.6;
    oxygenConcentrationFlag = false
  }

  let itemTitles = options.isEnglish ? ItemTitlesEn : ItemTitlesJa;
  if (options.isEnglish) {
    statusStr[0] = setStatusString_English(statusValue[0]);
  } else {
    statusStr[0] = setStatusString(statusValue[0]);
  }

  let processEndOpacity = 0.0;
  if (statusValue[0] === 17) { // 加工中
    processEndOpacity = 1.0;
  }

  // Gauge settings.
  // https://github.com/grafana/grafana/blob/main/packages/grafana-ui/src/components/Gauge/Gauge.tsx
  const thresh: ThresholdsConfig = {
    mode: ThresholdsMode.Absolute,
    steps: oxygenConcentrationFlag ?
    [
      { value: -Infinity, color: designColors.Gray800 },
      { value: 0.03, color: designColors.Gray800 },
      { value: 5.0, color: 'yellow' },
      { value: 19.5, color: 'red' },
    ]
    :
    [
      { value: -Infinity, color: designColors.Gray800 },
    ],
  };
  const showThresholdMarkers = true;
  const showThresholdLabels = true;
  const field: FieldConfig = {
    color: {
      mode: FieldColorModeId.Thresholds,
    },
    min: 0.03,
    max: 25,
    decimals: 2,
    thresholds: thresh,
    unit: "Percent (0-100)",
  };
  // const text: VizTextDisplayOptions = {
  //   titleSize: undefined,
  //   valueSize: undefined,
  // };
  // const openMenu = (event: React.MouseEvent<HTMLInputElement>) => {
  //   //処理
  // };
  const value: DisplayValue = {
    color: designColors.Gray800,
    numeric: orgRound(oxygenConcentration[0], 100),
    text: orgRound(oxygenConcentration[0], 100).toString() + "%",
  };

  let processEndDate = processEndTime[0]
  if (options.isEnglish) {
    processEndDate = '( ~ ' + new Date(processEndTime[0].slice(4,-1)).toString().slice(4, 21) + ')'
  }    

  let maintainanceDate = maintenanceSchedule[0]
  if (options.isEnglish) {
    maintainanceDate = new Date(maintenanceSchedule[0]).toDateString().slice(4)
  }    

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          // width: 1840px;
          height: 440px;
          // width: {width};
          // height: {height};
          `
      )}
      // className={styles.wrapper}
      style={{opacity: statusOpacity}}
    >
      <div className={styles.panel}>
        <div className={styles.title}>
          {machineName[0]}
        </div>
        <div className={styles.subpanel}>
          <div className={styles.status} style={{color: statusColor, backgroundColor: statusBgColor}}>
            <ReactFitty minSize={11} maxSize={44}>
              {statusStr[0]}
            </ReactFitty>
            {/* status: {status} */}
          </div>
          <div className={styles.job}>
            <div className={styles.jobNamePanel}>
              <div className={styles.jobNameTitle}>
                {itemTitles.ProcessJobName}
              </div>
              <div className={styles.jobName}>
                <ReactFitty minSize={11} maxSize={44}>
                  {processJobName[0]}
                </ReactFitty>
              </div>
            </div>
            <div className={styles.processPanel}>
              <div className={styles.processRemainTimeTitle}>
                {itemTitles.ProcessRemainTime}
              </div>
              <div className={styles.processRemainTime}>
                {processRemainTime2String(processRemainTime[0])}
                {/* <ReactFitty minSize={11} maxSize={66}>
                  {processRemainTime2String(processRemainTime[0])}
                </ReactFitty> */}
              </div>
              <div style={{'fontSize': '50%', /*'fontFamily': 'Roboto',*/ 'fontWeight': 'bold', 'color': designColors.Gray800, 'opacity': processEndOpacity}}>
                {processEndDate}
              </div>
              <div className={styles.processProgressBar}>
                <ProgressBar bgcolor={"#0E8757"} completed={orgRound(processProgressRate[0], 100)} />
              </div>
            </div>
          </div>
          <div className={styles.oximeterPanel}>
            <div className={styles.oximeterTitle}>
                {itemTitles.Oximeter}
            </div>
            <div className={styles.oximeterGauge}>
              <Gauge
                // value={clearNameForSingleSeries(count, fieldConfig.defaults, display)}
                value={value}
                width={width * 0.15}
                height={height}
                field={field}
                // text={text}
                showThresholdLabels={showThresholdLabels}
                showThresholdMarkers={showThresholdMarkers}
                theme={theme}
                // onClick={openMenu}
                className="oxygenConcentration"
              />
            </div>
          </div>
          {/* 粉体情報。APF接続有無で表示切替 */}
          {
            noApf() ?
              <PowderComponent powderInstalled={powderInstalled[0]} powderRemain={powderRemain[0]} styles={styles}
                                theme={theme} itemTitles={itemTitles} width={width} height={height}/>
              :
              <ApfPowderComponent apfPowderInfoList={apfPowderInfoList} styles={styles}
                                theme={theme} itemTitles={itemTitles} width={width} height={height}/>
          }
          <div className={styles.additionalInfo}>
            <div className={styles.modelNamePanel}>
              <div className={styles.modelNameTitle}>
                {itemTitles.Model}
              </div>
              <div className={styles.modelName}>
                <ReactFitty minSize={11} maxSize={44}>
                  {modelName[0]}
                </ReactFitty>
              </div>
            </div>
            { // ソフトバージョン。 APFの有無で表示切替
              noApf() ? 
                <SoftwareVersionComponent verStr={softwareVersion[0]} styles={styles} itemTitles={itemTitles}/>
                :
                <ApfSoftwareVersionComponent machineVerStr={softwareVersion[0]} apfVerStr={apfSoftwareVersion[0]} styles={styles} itemTitles={itemTitles}/>
            }
            { // シリアルナンバー。 APFの有無で表示切替
              noApf() ?
                <SerialNumberComponent snStr={serialNumber[0]} styles={styles} itemTitles={itemTitles}/>
                :
                <ApfSerialNumberComponent machineSnStr={serialNumber[0]} apfSnStr={apfSerialNumber[0]} styles={styles} itemTitles={itemTitles}/>
            }
            <div className={styles.maintainancePanel}>
              <div className={styles.maintainanceTitle}>
                {itemTitles.MaintainanceLimit}
              </div>
              <div className={styles.maintainance}>
                {/* {maintenanceSchedule} */}
                <ReactFitty minSize={11} maxSize={32}>
                  {maintainanceDate}
                </ReactFitty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


/** 粉体パネル（APFなし） */
const PowderComponent: React.FC<{powderInstalled: string, powderRemain: number, styles: any, theme: GrafanaTheme2, itemTitles: any, width: number, height: number}> = ({ powderInstalled, powderRemain, styles, theme, itemTitles, width, height }) => {
  const powderIconInfo = createPowderIconInfo(powderRemain);

  return(
    <div className={styles.powder}>
      <div className={styles.powderKindPanel}>
        <div className={styles.powderKindTitle}>
          {itemTitles.PowderKind}
        </div>
        <div className={styles.powderKind}>
          <ReactFitty minSize={11} maxSize={44}>
            {powderInstalled}
          </ReactFitty>
        </div>
      </div>
      <div className={styles.powderRemainPanel}>
    <div className={styles.powderRemainTitle}>
      {itemTitles.PowderRemain}
    </div>
    <div className={styles.powderRemain} style={{color: powderIconInfo.powderColor}}>
      <img className={styles.powderRemainImg} style={{opacity: powderIconInfo.powderIconOpacity}} src={powderIconInfo.powderIcon} />
      {orgRound(minusValueCheck(powderRemain), 100)} <span style={{'fontSize': '50%'}}>kg</span>
      <BarGauge
        value={{
          numeric: powderRemain,
          text: "",
          color: powderIconInfo.powderColor, 
        }}
        width={width * 0.10}
        height={height * 0.40}
        orientation={VizOrientation.Vertical}
        field={{
          color: {
            fixedColor: powderIconInfo.powderColor, 
            mode: FieldColorModeId.Fixed,
          },
          min: 0,
          max: 7,
        }}

        theme={theme}
        displayMode={BarGaugeDisplayMode.Basic}
        className={styles.powderRemainBarGauge}
        />
        </div>
      </div>
    </div>
  );
};

/** APF接続時の粉体情報パネル */
const ApfPowderComponent: React.FC<{apfPowderInfoList: PowderInfo[], styles: any, theme: GrafanaTheme2, itemTitles: any, width: number, height: number}> = ({ apfPowderInfoList, styles, theme, itemTitles, width, height }) => {
  //タンク数を求める
  let tankNum = 4;
  if (!apfPowderInfoList[3].isPowderUsed || apfPowderInfoList[3].isPowderUsed == null) { 
    tankNum = 3; //4番目のタンクの情報なければタンク数は3個
  }
  if (!apfPowderInfoList[2].isPowderUsed || apfPowderInfoList[2].isPowderUsed == null) { 
    tankNum = 2; //さらに3番目のタンクの情報もなければタンク数は3個
  }
  if (!apfPowderInfoList[1].isPowderUsed || apfPowderInfoList[1].isPowderUsed == null) { 
    tankNum = 1; //さらに2番目のタンクの情報もなければタンク数は1個。ただし、APFは複数タンク前提のため実際にはありえない。
  }
  if (!apfPowderInfoList[0].isPowderUsed || apfPowderInfoList[0].isPowderUsed == null) { 
    tankNum = 0; //実際にはありえない
  }

  //タンク数に応じて表示を分ける
  return (
    tankNum < 3 ? 
      <ApfPowderComponentTwoFrames apfPowderInfoList={apfPowderInfoList} tankNum={tankNum} styles={styles} theme={theme} itemTitles={itemTitles} width={width} height={height}/> 
      : 
      <ApfPowderComponentFourFrames apfPowderInfoList={apfPowderInfoList} tankNum={tankNum} styles={styles} theme={theme} itemTitles={itemTitles} width={width} height={height}/>
  );
};

/** APF接続時の粉体情報パネル。2分割。*/
const ApfPowderComponentTwoFrames: React.FC<{apfPowderInfoList: PowderInfo[], tankNum: number, styles: any, theme: GrafanaTheme2, itemTitles: any, width: number, height: number}> = ({ apfPowderInfoList, tankNum, styles, theme, itemTitles, width, height }) => {
  return (
    <div className={styles.apfPowder}>
      <div className={styles.apfPowderTitle}>{itemTitles.ApfPowders}</div>  {/* タイトル */}
      <div className={styles.apfPowderRow}> {/* 横に並べる */}
        <ApfPowderPanel powderInfo={apfPowderInfoList[0]} tankNo={1} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
        {
          tankNum > 1 ? 
            <ApfPowderPanel powderInfo={apfPowderInfoList[1]} tankNo={2} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
              : <ApfEmptyPowderPanel styles={styles}/> //ありえないが、タンク数が1個だけのときは空パネルを追加。念のため
        }
      </div>
    </div>
  );
}

/** APF接続時の粉体情報パネル。4分割。*/
const ApfPowderComponentFourFrames: React.FC<{apfPowderInfoList: PowderInfo[], tankNum: number, styles: any, theme: GrafanaTheme2, itemTitles: any, width: number, height: number}> = ({ apfPowderInfoList, tankNum, styles, theme, itemTitles, width, height }) => {
  return(
    <div className={styles.apfPowder}>
      <div className={styles.apfPowderTitle}>{itemTitles.ApfPowders}</div>  {/* タイトル */}
      <div className={styles.apfPowderRowInFourFrames}> {/* 上段 */}
        <ApfPowderPanel powderInfo={apfPowderInfoList[0]} tankNo={1} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
        <ApfPowderPanel powderInfo={apfPowderInfoList[1]} tankNo={2} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
      </div>
      <div className={styles.apfPowderRowInFourFrames}> {/* 下段 */}
        <ApfPowderPanel powderInfo={apfPowderInfoList[2]} tankNo={3} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
        { 
          tankNum > 3 ?  
            <ApfPowderPanel powderInfo={apfPowderInfoList[3]} tankNo={4} styles={styles} theme={theme} width={width} itemTitles={itemTitles} height={height}/>
            :
            <ApfEmptyPowderPanel styles={styles}/>
        }
      </div>
    </div>
  );
};


/** APF接続時に粉体残量を表示するパネル */
const ApfPowderPanel: React.FC<{powderInfo: PowderInfo, tankNo: number, styles: any, theme: GrafanaTheme2, itemTitles: any, width: number, height: number}> = ({powderInfo, tankNo: tankIndex, styles, theme, itemTitles, width, height}) => {
  return (
    <div className={styles.apfPowderPanel}>
    {/* 装置がタンク使用中 or notのAPIに対応したら以下に置き換えること 
      <div className={powderInfo.isPowderUsed ? styles.apfUsedPowderPanel : styles.apfPowderPanel}> 
    */}
      {/* 粉体タンクの番号と使用状況 */}
      <div className={styles.apfPowderTankStatus}>
        <div className={styles.apfPowderTankIndex}>{tankIndex}</div>
        {/* 装置がタンク使用中 or notのAPIに対応したらコメントを外すこと  
          powderInfo.isPowderUsed ? " " + itemTitles.InUse : "" 
        */}
      </div>
      {/* 粉体名 */}
      <div className={styles.powderKind}>
        <ReactFitty minSize={5} maxSize={20}>{powderInfo.powderInstalled}</ReactFitty>
      </div>
      {/* 粉体残量 */}
      <div className={styles.apfPowderRemain} style={{color: powderInfo.iconInfo.powderColor}}>
        <img className={styles.apfPowderRemainImg} style={{opacity: powderInfo.iconInfo.powderIconOpacity}} src={powderInfo.iconInfo.powderIcon} />
        {orgRound(minusValueCheck(powderInfo.powderRemain), 100)} <span style={{'fontSize': '45%'}}>kg</span>
        <BarGauge
          value={{
          numeric: powderInfo.powderRemain,
          text: "",
          color: powderInfo.iconInfo.powderColor, 
          }}
          width={width * 0.05}
          height={height * 0.40}
          orientation={VizOrientation.Vertical}
          field={{
            color: {
              fixedColor: powderInfo.iconInfo.powderColor, 
              mode: FieldColorModeId.Fixed,
            },
            min: 0,
            max: 7,
          }}
          theme={theme}
          displayMode={BarGaugeDisplayMode.Basic}
          className={styles.powderRemainBarGauge}
        />
      </div>
    </div>
  )
}


/** APFで接続されていないタンクを表す空パネル */
const ApfEmptyPowderPanel: React.FC<{styles: any}> = ({styles}) => {
  return (
    <div className={styles.apfEmptyPowderPanel}/>
  )
}

/** ソフトバージョンパネル。APFなし */
const SoftwareVersionComponent: React.FC<{verStr: string, styles: any, itemTitles: any}> = ({verStr, styles, itemTitles}) => {
  return (
    <div className={styles.softwareVersionPanel}>
      <div className={styles.softwareVersionTitle}>
        {itemTitles.SoftwareVersion}
      </div>
      <div className={styles.softwareVersion}>
        {verStr}
      </div>
    </div>
  )
}

/** ソフトバージョンパネル。APFあり */
const ApfSoftwareVersionComponent: React.FC<{machineVerStr: string, apfVerStr: string, styles: any, itemTitles: any}> = ({machineVerStr, apfVerStr, styles, itemTitles}) => {
  return (
    <div className={styles.apfSoftwareVersionPanel}>
      <div className={styles.softwareVersionTitle}>
        {itemTitles.SoftwareVersion}
      </div>
      <div className={styles.apfVersionRow}>
        <div className={styles.apfVersionTag}>{itemTitles.Machine}</div>
        <div className={styles.apfVersionStr}>{machineVerStr}</div>
      </div>
      <div className={styles.apfVersionRow}>
        <div className={styles.apfVersionTag}>APF</div>
        <div className={styles.apfVersionStr}>{apfVerStr}</div>
      </div>
    </div>
  )
}

/** シリアルナンバー表示パネル。APFなし */
const SerialNumberComponent: React.FC<{snStr: string, styles: any, itemTitles: any}> = ({snStr, styles, itemTitles}) => {
  return (
    <div className={styles.serialNumberPanel}>
      <div className={styles.serialNumberTitle}>
        {itemTitles.SerialNumber}
      </div>
      <div className={styles.serialNumber}>
        <ReactFitty minSize={11} maxSize={32}>
          {snStr}
        </ReactFitty>
      </div>
  </div>
  )
}

/** シリアルナンバー表示パネル。APFあり。スタイルはバージョンパネルを流用 */
const ApfSerialNumberComponent: React.FC<{machineSnStr: string, apfSnStr: string, styles: any, itemTitles: any}> = ({machineSnStr, apfSnStr, styles, itemTitles}) => {
  return (
    <div className={styles.apfSoftwareVersionPanel}>
      <div className={styles.serialNumberTitle}>S/N</div>
      <div className={styles.apfVersionRow}>
        <div className={styles.apfVersionTag}>{itemTitles.Machine}</div>
        <div className={styles.apfVersionStr}>{machineSnStr}</div>
      </div>
      <div className={styles.apfVersionRow}>
        <div className={styles.apfVersionTag}>APF</div>
        <div className={styles.apfVersionStr}>{apfSnStr}</div>
      </div>
    </div>
  )
}


const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    position: relative;
    background-color: #21262D;
    border:1px solid #3F444B;
    font-size: 44px;
    object-fit: cover;

    @media only screen and (max-width: 1200px) {
      font-size: 32px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 22px;
    }
    `,
  panel: css`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    height: 12.5%;
    // font-family: Roboto;
    font-size: 100%;
    font-weight: bold;
    color: #BFC3C7;
    // text-align: center;
    // vertical-align: center;
  `,
  subpanel: css`
    // position: relative;
    height: 87.5%;
    width: 100%;
    display: flex;
    align-items: stretch;
    border:1px solid #3F444B;
  `,
  status: css`
    width: 10%;
    // font-family: Roboto;
    font-size: 50%;
    font-weight: bold;
    // color: #BFC3C7;
    // background-color: #0E8757;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  job: css`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // text-align: center;
    border:1px solid #3F444B;
  `,
  jobNamePanel: css`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  jobNameTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  jobName: css`
    width: 100%;
    // font-family: Roboto;
    // font-size: 100%;
    font-weight: bold;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    color: #BFC3C7;
    text-align: center;
    // border:3px solid #F00;
  `,
  processPanel: css`
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  processRemainTimeTitle: css`
    height: 10%;
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
    // vertical-align: center;
  `,
  processRemainTime: css`
    height: 75%;
    width: 100%;
    // font-family: Roboto;
    font-size: 150%;
    font-weight: bold;
    color: #BFC3C7;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    // border:1px solid orange;

    @media only screen and (max-width: 1200px) {
      font-size: 125%;
    }
  `,
  processProgressBar: css`
    height: 15%;
    width: 95%;
    font-size: 12px;
    // vertical-align: center;
    // border:1px solid orange;
  `,
  oximeterPanel: css`
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  oximeterTitle: css`
    height: 10%;
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    text-align: center;
    color: #BFC3C7;
    // vertical-align: center;
  `,
  oximeterGauge: css`
    height: 90%;
    width: 100%;
    color: #BFC3C7;
    // vertical-align: center;
    // border:1px solid orange;
  `,
  powder: css`
    width: 20%;
    text-align: center;
    border:1px solid #3F444B;
  `,
  powderKindPanel: css`
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  powderKindTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  powderKind: css`
    width: 100%;
    // font-family: Roboto;
    // font-size: 100%;
    font-weight: bold;
    text-align: center;
    color: #BFC3C7;
  `,
  powderRemainPanel: css`
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  powderRemainTitle: css`
    height: 15%;
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
    // vertical-align: center;
  `,
  powderRemain: css`
    height: 85%;
    // font-family: Roboto;
    font-size: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // color: #BFC3C7;
    // border:1px solid orange;
  `,
  powderRemainImg: css`
    width: 36px;

    @media only screen and (max-width: 1200px) {
      width: 24px;
    }
    `,
  powderRemainBarGauge: css`
    height: 65%;
    flex-direction: column;
    align-items: center;
    // border:1px solid orange;
  `,
  additionalInfo: css`
    width: 20%;
    text-align: center;
    border:1px solid #3F444B;
  `,
  modelNamePanel: css`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  modelNameTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  modelName: css`
    width: 100%;
    // font-family: Roboto;
    // font-size: 100%;
    font-weight: bold;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    text-align: center;
    color: #BFC3C7;
  `,
  softwareVersionPanel: css`
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  softwareVersionTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  softwareVersion: css`
    // width: 100%;
    // font-family: Roboto;
    font-size: 100%;
    font-weight: bold;
    text-align: center;
    color: #BFC3C7;
  `,
  serialNumberPanel: css`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  serialNumberTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  serialNumber: css`
    width: 100%;
    // font-family: Roboto;
    // font-size: 100%;
    font-weight: bold;
    // white-space: nowrap;
    text-align: center;
    color: #BFC3C7;
  `,
  maintainancePanel: css`
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:1px solid #3F444B;
  `,
  maintainanceTitle: css`
    // font-family: Roboto;
    font-size: 50%;
    // font-weight: bold;
    color: #BFC3C7;
  `,
  maintainance: css`
    width: 100%;
    // font-family: Roboto;
    // font-size: 100%;
    font-weight: bold;
    align-items: center;
    color: #BFC3C7;
  `,
  apfPowder: css`
    text-align: center;
    border:1px solid #3F444B;
    min-width: 18%; //apfはレイアウトが複雑なため、表示切れが起きないようあらかじめwidthを確保
    flex-grow: 1;
  `,
  apfPowderTitle: css`
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50%;
    color: #BFC3C7;
  `,
  apfPowderRow: css`
    width: 100%;
    height: 90%;  //100% - title 10%
    display: flex;
    align-items: stretch;
    border:1px solid #3F444B;
  `,
  apfPowderPanel: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border:1px solid #3F444B;
    flex-grow: 1;
    max-width: 50%;
  `,
  apfUsedPowderPanel: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border:2px solid #BFC3C7;
    flex-grow: 1;
    max-width: 50%;
  `,
  apfEmptyPowderPanel: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border:1px solid #3F444B;
    flex-grow: 1;
    max-width: 50%;
  `, 
  apfPowderTankStatus: css`
    height: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 25%;
    color: #BFC3C7;
  `,
  apfPowderTankIndex: css`
    align-items: center;
    text-align: center;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    background-color: #3F444B;
    color: #ffffff;
  `,
  apfPowderRemain: css`
    height: 85%;
    font-size: 45%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  apfPowderRemainImg: css`
    width: 22px;

    @media only screen and (max-width: 1200px) {
      width: 11px;
    }
  `,
  apfPowderRowInFourFrames: css`
    width: 100%;
    height: 45%;  //100% - タイトル10% / 2 = 45%
    display: flex;
    align-items: stretch;
    border:1px solid #3F444B;
  `,
  apfSoftwareVersionPanel: css`
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: stretch; //Changed
    border:1px solid #3F444B;
  `,
  apfVersionRow: css`
    height: 37.5%; //100% - タイトル25% / 2 = 37.5%
    display: flex;
    align-items: center;
  `,
  apfVersionTag: css`
    width: 20%;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 30%;
    text-align: left;
    color: #BFC3C7;
  `,
  apfVersionStr: css`
    width: 70%;
    font-size: 40%;
    font-weight: bold;
    text-align: left;
    color: #BFC3C7;
    flex-glow: 1;
`,
});

const ProgressBar = (props: any) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 12,
    width: '80%',
    // backgroundColor: "#e0e0de",
    backgroundColor: designColors.Gray400,
    borderRadius: 0,
    margin: 10,
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    'text-align': 'right'
  }

  const labelStyles = {
    padding: 5,
    // color: '#21262D',
    color: designColors.Gray800,
    // fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold'
  }

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>{`${completed}%`}</span> */}
        </div>
      </div>
      <span style={labelStyles}>{`${completed}%`}</span>
    </div>
  );
};

function orgRound(value: number, base: number) {
  return Math.round(value * base) / base;
}

function minusValueCheck(value: number) {
  if (value < 0) {
    return 0.0;
  }
  return value;
}

function processRemainTime2String(value: number) {
  // ~~()は、小数点以下を削除
  const h = ~~(value / 3600);
  const m = ~~((value % 3600) / 60);
  return h + 'h ' + m + ' m';
}

function setStatusString(value: number) {
  let str = '状態不明';

  if (value === -1) {
    str = '通信不能';
  }
  else if (value === -2) {
    str = 'シャットダウン';
  }
  else if (value >= 0 && value <= 2) {
    str = '起動中';
  }
  else if (value >= 3 && value <= 5) {
    str = '待機中';
  }
  else if (value >= 6 && value <= 9) {
    str = '不活性ガス 充填中';
  }
  else if (value === 10) {
    str = '加工開始可能';
  }
  else if (value >= 11 && value <= 13) {
    str = '不活性ガス 制御停止';
  }
  else if (value >= 14 && value <= 16) {
    str = '不活性ガス 排出中';
  }
  else if (value === 17) {
    str = '加工中';
  }
  else if (value === 18) {
    str = 'システムエラー';
  }
  else if (value >= 19 && value <= 20) {
    str = '終了中';
  }
  else if (value === 21) {
    str = '酸素濃度センサーエラー';
  }
  else if (value >= 22 && value <= 23) {
    str = 'メンテナンス中';
  }
  else if (value >= 24 && value <= 25) {
    str = '不活性ガス クイック充填完了';
  }

  return str;
}

function setStatusString_English(value: number) {
  let str = 'Status unknown';
  if (value === -1) {
    str = 'Communication error';
  }
  else if (value === -2) {
    str = 'Shutdown';
  }
  else if (value >= 0 && value <= 2) {
    str = 'Starting up';
  }
  else if (value >= 3 && value <= 5) {
    str = 'Idling';
  }
  else if (value >= 6 && value <= 9) {
    str = 'Filling inert gas';
  }
  else if (value === 10) {
    str = 'Ready to start processing';
  }
  else if (value >= 11 && value <= 13) {
    str = 'Inert gas control stopped';
  }
  else if (value >= 14 && value <= 16) {
    str = 'Exhausting inert gas';
  }
  else if (value === 17) {
    str = 'In process';
  }
  else if (value === 18) {
    str = 'System error';
  }
  else if (value >= 19 && value <= 20) {
    str = 'Shutting down';
  }
  else if (value === 21) {
    str = 'Oxygen concentration sensor error';
  }
  else if (value >= 22 && value <= 23) {
    str = 'Under maintenance';
  }
  else if (value >= 24 && value <= 25) {
    str = 'Quick inert gas filling completed';
  }

  return str;
}

/** 粉体残量に応じた、粉体情報のアイコンの種類・色・Opacityを返す */
function createPowderIconInfo(powderRemain: number): PowderIconInfo {
  let powderIcon = WarnIcon;
  let powderColor = designColors.Gray800;
  let powderIconOpacity = 0.0;
  if (powderRemain == null){
    //何もしない
  }
  else if (powderRemain < 1.0) {
    powderIcon = CriticalIcon;
    powderColor = designColors.Error;
    powderIconOpacity = 1.0;
  }
  else if (powderRemain < 2.0) {
    powderIcon = WarnIcon;
    powderColor = designColors.Warn;
    powderIconOpacity = 1.0;
  }

  return {
    powderIcon: powderIcon,
    powderColor: powderColor,
    powderIconOpacity: powderIconOpacity,
  }

}

/** 文字列をbooleanに変換する */
function stringToBoolean(value: string | null | undefined): boolean {
  // null、undefined は false
  if (!value) {
      return false;
  }

  // 小文字にして判定
  return value.toLowerCase() === 'true' || value === '1';
}
